<template>
    <div class="casino-game">
        <div class="casino-game-image" @click="showDiv()">
            <img :src="getImageUrl(game)" alt="img">
            <div class="casino-game-overlay" :class="{'selected' : show}">
                <button class="btn btn-sm bg-orange" style="margin-bottom: 10px;" @click="$emit('playDemo',game)" v-if="!type">{{ $t('casino.play-demo') }}</button>
                <button class="btn btn-sm bg-orange" @click="$emit('playGame',game)">{{ $t('casino.play-game') }}</button>
            </div>
        </div>
        <div class="casino-game-info">
            <span>{{ game.gameName }}</span>
            <span><i class="icofont-star"></i></span>
        </div>
    </div>
</template>
<script>
import { CASINO } from "@/config/casino_confs";
export default {
    props:['game','type'],
    data() {
        return {
            show:false,
        }
    },
    methods: {
        showDiv: function() {
            this.show = !this.show;
        },
        getImageUrl(game) {
            return CASINO.getImageUrl(game.gameID);
        },
    }
}
</script>
<style scoped>
    .casino-game-info span:hover {
        cursor: pointer;
        color: var(--bg-orange);
    }
</style>