<template>
    <div v-frag>
        <loading 
            :active="loadingGames"
            :can-cancel="true"
            :is-full-page="fullPage"
        />
        <paginate
            name="games"
            :list="liveCasinoGames"
            :per="18"
            class="paginate-ul casino-body"
            >
            <div v-frag v-for="game in paginated('games')" :key="game.gameID">
                <card :game="game" @playGame="goTolobby" @playDemo="startDemo" type="virtual" />
            </div>
        </paginate>
        <div class="text-center">
            <paginate-links for="games" :show-step-links="true" class="mt-4 VuePagination__pagination pagination VuePagination__pagination page-link "></paginate-links>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {mapState, mapGetters} from "vuex"
import { CASINO } from "@/config/casino_confs";
import card from "./casino/casino.show.vue";

export default {
    name: "live_casino",
    components: {
        // SubLive,
        Loading,
        card
    },
    computed: {
        ...mapGetters(["liveCasinoGames", "loadingGames"]),
        ...mapState(["Casino"]),
    },
    data() {
        return {
            fullPage:true,
            computedGames: [],
            gameUrl: "",
            paginate:["games"]
        };
    },
    mounted() {
        this.$store.dispatch("getCasinoGames", "live");
    },
    methods: {
        goTolobby: function () {
            this.$router.push({name:"casino-lobby"}).catch(()=>{ console.log("I KNOW PLEASE")})
        },
        getImageUrl(game) {
        return CASINO.getImageUrl(game.gameID);
        },
        startDemo() {
            console.log('demo');
        }
    },
};
</script>

<style scoped>
    @media screen and (max-width: 767px) {
        ul {
            overflow-x: scroll;
            white-space: nowrap;
        }
        .featured-header.links {
            border-top: 0px;
        }
        .featured-header {
            background-color: transparent;
        }
    }
    ul li {
        cursor: pointer;
        font-weight: bold;
    }
    .casino {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .casino-card {
        border: 1px solid #494d59;
        border-radius: 10px;
        position: relative;
        /* background-color: #494d59 ; */
    }
    .casino-img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;     
        max-height: 180px;
    }
    .casino-card-body {
        padding: 30px 10px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 10px;
        opacity: 0;
        background-color: rgb(0, 0, 0);
        transition: all 0.3s ease-in-out 0s;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .casino-card:hover .casino-card-body {
        opacity: 0.95;
        z-index: 200;
    }
    .casino-card-body .play {
        display: flex;
        justify-content: space-around;
    }
    .play-game {
        background-color: #f7941e;
        border: 1px solid #f7941e;
        color: #ffffff;
        font-weight: bold;
        font-size: 14px;
        padding: 4px 20px;
        opacity: 1;
    }
    .play-demo {
        background-color: #073b6b;
        border: 1px solid #073b6b;
        color: #ffffff;
        font-weight: bold;
        font-size: 14px;
        padding: 4px 20px;
    }
    @media screen and (max-width: 767px) {
        .casino-card-body .play {
            justify-content: space-between;
            flex-direction: column;
        }
        .casino-card-body {
            padding: 10px 5px;
        }
        .casino-card-body .hed {
            font-size: 16px;
            margin-top: 5px;
        }
        .play-demo {
            margin-bottom:5px;
        }
        .casino-img {
            min-height: 150px;
        }
        .featured-header.links {
            border-top: 0px;
        }
        .featured-header {
            background-color: transparent;
            padding: 5px;
        }
        .featured-header ul {
            margin-bottom: 5px;
        }
        .casino-img {
            border-radius: 10px;
        }
    }
</style>